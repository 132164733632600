/************************** Variables START ******************************/
/************************** Variables START ******************************/
/************************** Shared START ******************************/
body {
  background-color: #343a40; }

/* Text sizes */
h1 {
  font-size: 3.5em;
  line-height: 1.1em; }

h2 {
  font-size: 2.5em;
  line-height: 1.1em; }

p {
  font-size: 1.2em; }

/************************** Shared END ******************************/
/************************** Header START ******************************/
.navbar-dark .btn {
  border-radius: 5px; }

.navbar-dark li a:hover {
  text-decoration: underline; }

.navbar-dark .logo {
  height: 2.2em; }

/************************** Header END ******************************/
/************************** Homepage Jumbotron START ******************************/
.jumbotron {
  background-image: url("./images/bannerShort.jpg");
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px;
  width: 100%;
  margin-bottom: 0px;
  background-position-x: right; }
  .jumbotron .container {
    text-align: center; }

/************************** Homepage Jumbotron END ******************************/
/************************** Information Panels START ******************************/
.panel {
  padding-top: 50px;
  padding-bottom: 70px;
  text-align: center; }
  .panel h2 {
    margin-bottom: 30px;
    margin-top: 30px; }
  .panel p {
    font-size: 1em; }

.white_panel {
  background-color: #f5f4f4;
  color: black; }

#how_it_works {
  background-color: #CF1202;
  color: white; }
  #how_it_works i {
    font-size: 5em;
    margin-bottom: 15px;
    margin-top: 25px; }

.tips_img {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 15px; }

/************************** Information Panels END ******************************/
/************************** Footer START ******************************/
#footer {
  margin: 10px;
  font-size: 1em;
  color: white; }

/************************** Footer END ******************************/
/************************** FormSTART ******************************/
.add-btn {
  font-size: 1em; }

.car-modal {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  background-color: white;
  border: 1px solid #cccccc;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 3; }

/************************** Form END ******************************/
.my-card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 50px;
  border-color: #C4CDE1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
  background-color: white; }

.card-header {
  margin-bottom: 20px;
  background-color: #0275d8;
  color: white;
  text-align: center; }

.input-group-addon {
  background-color: white;
  color: black; }

.input-group-addon i {
  text-align: center;
  width: 20px; }

img {
  max-height: 100%; }

.no-padding {
  padding: 0px;
  overflow: hidden; }

.move-form {
  padding: 20px; }

.form-img {
  background-image: url("images/formImg.jpg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 100%; }

label {
  margin-bottom: 2px; }

.my-invalid-feedback {
  color: #dc3545;
  font-size: 0.8em; }

.home-move {
  color: white;
  max-width: 500px; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}